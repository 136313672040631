import { combineReducers } from '@reduxjs/toolkit';
import { layoutSlice } from '@root/infra/layout/redux';
import { authSlice } from '@root/modules/auth/redux/auth/auth.slice';
import { countriesSlice } from '@root/modules/countries/redux';
import { membershipSlice } from '@root/modules/membership/redux/membership.slice';
import { metaSlice } from '@root/modules/meta/redux/meta.slice';
import { userAgentSlice } from '@root/shared/user-agent';
import { settingsSlice } from '@root/modules/settings/store/settings.slice';
import { ordersSlice } from '@root/modules/orders/store/orders.slice';
import { productsSlice } from '@root/modules/products/redux/products/products.slice';
import { productCategoriesSlice } from '@root/modules/products/redux/product-categories/product-categories.slice';
import { purchasedProductsSlice } from '@root/modules/products/redux/purchased-products/purchased-products.slice';
import { subscriptionsSlice } from '@root/modules/subscriptions/redux/subscriptions.slice';
import { paymentMethodsSlice } from '@root/modules/payment-methods/store/payment-methods.slice';
import { billingDetailsSlice } from '@root/modules/payment-methods/store/billing-details/billing-details.slice';
import { eWalletSlice } from '@root/modules/e-wallet/store/e-wallet.slice';
import { addressesSlice } from '@root/modules/payment-methods/store/addresses/addresses.slice';
import { servicesSlice } from '@root/modules/products/redux/services/services.slice';
import { kycSlice } from '@root/modules/kyc/redux/kyc.slice';
import { navigationSlice } from '@root/modules/app-navigation/redux/navigation.slice';
import { currenciesSlice } from '@root/modules/currencies/redux/currencies.slice';
import { appSettingsSlice } from '@root/modules/app-settings/store/app-settings.slice';

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  layout: layoutSlice.reducer,
  userAgent: userAgentSlice.reducer,
  meta: metaSlice.reducer,
  countries: countriesSlice.reducer,
  settings: settingsSlice.reducer,
  membership: membershipSlice.reducer,
  orders: ordersSlice.reducer,
  products: productsSlice.reducer,
  productCategories: productCategoriesSlice.reducer,
  purchasedProducts: purchasedProductsSlice.reducer,
  subscriptions: subscriptionsSlice.reducer,
  services: servicesSlice.reducer,
  paymentMethods: paymentMethodsSlice.reducer,
  billingDetails: billingDetailsSlice.reducer,
  eWallet: eWalletSlice.reducer,
  addresses: addressesSlice.reducer,
  kyc: kycSlice.reducer,
  navigation: navigationSlice.reducer,
  currencies: currenciesSlice.reducer,
  appSettings: appSettingsSlice.reducer,
});

export type AppState = ReturnType<typeof rootReducer>;
