import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { store } from '@root/infra/redux';
import { getCookie } from '@root/shared/cookies';

import { authSlice } from '../redux';
import { refreshTokenService } from '../services';

const SKIP_REFRESHING_URLS: string[] = ['/auth/signin-cp', '/auth/signin-cp-final', '/auth/refresh-tokens'];

export const refreshTokenInterceptor = async (error: AxiosError) => {
  const originalRequest = error.config;
  const refreshToken = getCookie('refreshToken');

  if (!refreshToken) {
    store.dispatch(authSlice.actions.loggedOut());
  }

  if (
    !!refreshToken &&
    !SKIP_REFRESHING_URLS.some((item) => (originalRequest.url as string).includes(item)) &&
    error.response?.status === 401 &&
    !(originalRequest as any)._retry
  ) {
    const response = await refreshTokenService(refreshToken);

    if (response.status === 200) {
      (originalRequest as any)._retry = true;
      const { accessToken, refreshToken } = response.payload;

      fetcher.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

      if (!originalRequest.headers) {
        originalRequest.headers = {};
      }

      originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;

      store.dispatch(authSlice.actions.tokensRefreshed({ accessToken, refreshToken }));

      return fetcher.request(originalRequest);
    } else {
      store.dispatch(authSlice.actions.loggedOut());
    }
  }
  return Promise.reject(error);
};
