import { MetaState } from './meta.slice';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { RECURRING_TYPES } from '@root/infra/enums/reccuring-type';
import { AppState } from '@root/infra/redux';
import { ILegalDocument } from '@root/modules/meta/types';

export const metaSelector = createSelector<[Selector<AppState, MetaState>], MetaState>(
  (state) => state.meta,
  (meta) => {
    return meta;
  },
);

export const globalPhoneValidationSelector = createSelector<[Selector<AppState, MetaState>], boolean>(
  (state) => state.meta,
  (meta) => {
    const setting = meta?.settings?.find((item) => item.code === 'GLOBAL_PHONE_VERIFICATION_ENABLED');

    if (setting) {
      return setting.value as boolean;
    }

    return true;
  },
);

export const selectSiteSettings = createSelector<[Selector<AppState, MetaState>], boolean>(
  (state) => state.meta,
  (meta) => {
    const setting = meta?.settings?.find((item) => item.code === 'GLOBAL_PHONE_VERIFICATION_ENABLED');

    if (setting) {
      return setting.value as boolean;
    }

    return true;
  },
);

export const visibleLegalDocumentSelector = createSelector<[Selector<AppState, MetaState>], ILegalDocument | undefined>([(state) => state.meta], (meta) =>
  meta.legalDocuments.find((item) => item.type === meta.visibleLegalDocument),
);

export const selectBaseInstanceCountry = createSelector<[Selector<AppState, MetaState>], string | undefined>(
  [(state) => state.meta],
  (meta) => meta.currentInstance?.baseCountry?.code || undefined,
);

export const selectCurrentInstanceCode = createSelector<[Selector<AppState, MetaState>], string | null>([(state) => state.meta], (meta) => meta.currentInstance?.code || null);

export const selectRecurringType = createSelector<[Selector<AppState, MetaState>], RECURRING_TYPES>([(state) => state.meta], (meta) =>
  meta.currentInstance?.code === import.meta.env.REACT_APP_ANNUAL_INSTANCE_CODE ? RECURRING_TYPES.ANNUAL : RECURRING_TYPES.MONTHLY,
);

export const selectCurrentInstanceWebsiteId = createSelector<[Selector<AppState, MetaState>], string | null>(
  [(state) => state.meta],
  (meta) => meta.currentInstance?.websiteId || null,
);
