import queryString from 'query-string';
import { call, put, SagaReturnType, select, takeLatest } from '@redux-saga/core/effects';

import { AppState } from '@root/infra/redux';
import { authSlice, authUserRecurringTypeSelector } from '@root/modules/auth/redux';
import { selectBaseInstanceCountry, selectRecurringType } from '@root/modules/meta/redux';

import { fetchMembershipLevelsService } from '../services/fetch-membership-levels.service';
import { membershipSlice } from './membership.slice';

const { fetchMembershipPending, fetchMembershipFulfilled, fetchMembershipRejected } = membershipSlice.actions;

function* fetchMembershipLevels() {
  const auth = yield select((state: AppState) => state.auth);
  const uiCountry = yield select(selectBaseInstanceCountry);
  const instanceRecurringType = yield select(selectRecurringType);
  const userRecurringType = yield select(authUserRecurringTypeSelector);
  const recurringType = userRecurringType || instanceRecurringType;
  const queryData = queryString.parse(window?.location?.search, { parseNumbers: true });
  const country = queryData?.country || auth?.country || auth?.user?.country || uiCountry;

  const result: SagaReturnType<typeof fetchMembershipLevelsService> = yield call(fetchMembershipLevelsService, { uiCountry: country, recurringType });

  if (result.status === 200) {
    yield put(fetchMembershipFulfilled(result.payload));
  } else {
    yield put(fetchMembershipRejected(result.payload));
  }
}

function* handleRecurringTypeChange() {
  const instanceRecurringType = yield select(selectRecurringType);
  const userRecurringType = yield select(authUserRecurringTypeSelector);
  if (instanceRecurringType !== userRecurringType) {
    yield call(fetchMembershipLevels);
  }
}

export function* membershipLevelsSaga() {
  yield takeLatest([fetchMembershipPending.type, authSlice.actions.countryUpdated, authSlice.actions.loggedOut, authSlice.actions.fetchProfileFulfilled], fetchMembershipLevels);
  yield takeLatest([authSlice.actions.setUserRecurringType.type], handleRecurringTypeChange);
}
