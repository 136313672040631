import * as yup from 'yup';
import { TFunction } from 'react-i18next';

export const signinValidation = (t: TFunction) =>
  yup.object().shape({
    email: yup.string(),
    username: yup.string().when('email', (email) => {
      if (email?.includes('@')) {
        return yup.string().email(t('fields.email.valid')).required(t('fields.email.required')).max(50).label('Email');
      }

      return yup.string().required(t('fields.username.required')).max(50).label('Username');
    }),
    password: yup
      .string()
      .required(t('fields.password.required'))
      // .min(8)
      // .max(20)
      // .matches(/[@#$%^&+=.\-_*!]+/g, 'Password should contain at least one special character')
      // .matches(/[A-Z]+/g, 'Password should contain at least one uppercase character')
      .label('Password'),
  });
