import { IProductsState } from '../products/products.slice';
import { createSelector, Dictionary, Selector } from '@reduxjs/toolkit';
import { AppState } from '@root/infra/redux';
import { PurchasedProductsState } from '@root/modules/products/redux/purchased-products/purchased-products.slice';
import { ICanBeSwitchedToItem, IPurchasedProduct } from '@root/modules/products/types/purchased-product';

export const purchasedProductsSelector = createSelector<[Selector<AppState, PurchasedProductsState>], PurchasedProductsState>(
  (state) => state.purchasedProducts,
  (products) => products,
);

export const purchasedProductsSwitchItemsSelector = createSelector<[Selector<AppState, PurchasedProductsState>], ICanBeSwitchedToItem[] | null>(
  purchasedProductsSelector,
  (products) => {
    if (products?.data) {
      return products.data.reduce((acc: ICanBeSwitchedToItem[], val) => {
        val?.canBeSwitchedTo?.map((switchItem: ICanBeSwitchedToItem) => acc.push(switchItem));
        return acc;
      }, []);
    }
    return null;
  },
);

export const purchasedProductsByIdSelector = createSelector<[Selector<AppState, PurchasedProductsState>], Dictionary<IPurchasedProduct> | null>(
  purchasedProductsSelector,
  (products) => {
    if (products.data) {
      return products.data.reduce((acc, val) => {
        acc[val.shopId] = val;
        return acc;
      }, {});
    }
    return null;
  },
);

export const purchasedBundleSelector = createSelector<[Selector<AppState, PurchasedProductsState>], PurchasedProductsState['data']>(
  [(state) => state.purchasedProducts],
  (purchasedProducts) => purchasedProducts.data.filter((item) => item.isDigitalBundle),
);

export const hasPurchasedBundleSelector = createSelector<[Selector<AppState, PurchasedProductsState>], boolean>(
  [(state) => state.purchasedProducts],
  (purchasedProducts) => purchasedProducts.data.findIndex((item) => item.isDigitalBundle) !== -1,
);

export const activePurchasedProductsSelector = createSelector<[Selector<AppState, PurchasedProductsState>], PurchasedProductsState['data']>(
  [(state) => state.purchasedProducts],
  (purchasedProducts) => purchasedProducts.data.filter((item) => !item.isExpired),
);

export const expiredPurchasedProductsSelector = createSelector<[Selector<AppState, PurchasedProductsState>], PurchasedProductsState['data']>(
  [(state) => state.purchasedProducts],
  (purchasedProducts) => purchasedProducts.data.filter((item) => item.isExpired),
);

export const menuSubscriptionsWarningSelector = createSelector<[Selector<AppState, PurchasedProductsState>], boolean>(
  [(state) => state.purchasedProducts],
  (purchasedProducts) => !!purchasedProducts.data.filter((item) => item.isExpired).length,
);

export const availableProductsSelector = createSelector<[Selector<AppState, IProductsState>, Selector<AppState, PurchasedProductsState>], Omit<IProductsState, 'error'>>(
  (state) => state.products,
  (state) => state.purchasedProducts,
  (products, purchasedProducts) => {
    if (!purchasedProducts.data.length) {
      return {
        isLoading: purchasedProducts.isLoading || products.isLoading,
        data: [],
      };
    }

    const addons = products.data.filter((item) => item.isAddon);
    const purchasedServicesIds = purchasedProducts.data
      .map((item) => item.services)
      .flat()
      .map((item) => item.id);

    const availableProducts = addons.filter((item) => !purchasedServicesIds.includes(item.primaryServiceId) && item.status === 'active');

    return {
      isLoading: purchasedProducts.isLoading || products.isLoading,
      data: availableProducts,
    };
  },
);

export const selectRedirectInstanceTypeByUserSubscription = createSelector<[Selector<AppState, PurchasedProductsState>], { isAnnual: boolean }>(
  [(state) => state.purchasedProducts],
  (purchasedProducts) => ({
    isAnnual: purchasedProducts.data?.some((s) => s?.recurringType === 'Annual'),
  }),
);
