import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompanyInfo, IInstance, ILegalDocument, IRegion, ISetting } from '../types';

export type MetaState = {
  loading: boolean;
  legalDocuments: ILegalDocument[];
  currentInstance: IInstance | null;
  settings: ISetting[] | null;
  regions: IRegion[];
  companyInfo: ICompanyInfo | null;
  error: string | null;
  visibleLegalDocument: string | null;
};

export namespace MetaActions {
  export type Pending = PayloadAction;
  export type Fulfilled = PayloadAction<{
    legalDocuments: ILegalDocument[];
    regions: IRegion[];
    currentInstance: IInstance;
    settings: ISetting[] | null;
    companyInfo: ICompanyInfo | null;
  }>;
  export type Rejected = PayloadAction<string>;
  export type LegalDocumentOpened = PayloadAction<string>;
  export type LegalDocumentClosed = PayloadAction;
}

export type MetaSliceReducer = {
  pending: CaseReducer<MetaState, MetaActions.Pending>;
  fulfilled: CaseReducer<MetaState, MetaActions.Fulfilled>;
  rejected: CaseReducer<MetaState, MetaActions.Rejected>;
  legalDocumentOpened: CaseReducer<MetaState, MetaActions.LegalDocumentOpened>;
  legalDocumentClosed: CaseReducer<MetaState, MetaActions.LegalDocumentClosed>;
};

export const metaInitialState: MetaState = {
  loading: false,
  error: null,
  legalDocuments: [],
  regions: [],
  settings: null,
  companyInfo: null,
  currentInstance: null,
  visibleLegalDocument: null,
};

export const metaSlice = createSlice<MetaState, MetaSliceReducer, 'meta'>({
  name: 'meta',
  initialState: metaInitialState,
  reducers: {
    pending: (state) => {
      state.loading = true;
    },
    fulfilled: (state, action) => {
      state.loading = false;
      state.legalDocuments = action.payload.legalDocuments;
      state.regions = action.payload.regions;
      state.currentInstance = action.payload.currentInstance;
      state.settings = action.payload.settings;
      state.companyInfo = action.payload.companyInfo;
    },
    rejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    legalDocumentOpened: (state, action) => {
      state.visibleLegalDocument = action.payload;
    },
    legalDocumentClosed: (state) => {
      state.visibleLegalDocument = null;
    },
  },
});
