import { DashboardUI } from './dashboard.ui';
import { useRedirection } from '@root/modules/auth/hooks/use-redirection';
import { REDIRECTION_TYPES } from '@root/modules/auth/types';
import { selectRedirectInstanceTypeByUserSubscription } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { IMiniProduct } from '@root/modules/products/types/mini-product';
import { BodyTextLarge, Button, Image } from '@root/shared/ui';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const { Card } = DashboardUI;

export const ProductCarouselCard: FC<{ item: IMiniProduct }> = ({ item }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'availableProducts' });
  const { redirect } = useRedirection();
  const { isAnnual } = useSelector(selectRedirectInstanceTypeByUserSubscription);

  const handleShowOnShop = useCallback(() => {
    redirect(REDIRECTION_TYPES.BUY, { planId: item.id, isAnnual });
  }, [redirect, item, isAnnual]);

  return (
    <Card>
      <div className='min-h-[60px] h-full flex flex-col lg:flex-row gap-5'>
        <div className='flex items-start gap-4'>
          <div className='rounded-lg overflow-hidden bg-gray-200'>
            <Image src={item.imageUrl} className='object-cover w-14 h-10 lg:w-20 lg:h-16' alt='card' />
          </div>
          <BodyTextLarge className='font-bold self-center'>{item.title.text}</BodyTextLarge>
        </div>
        <Button className='w-full py-2 px-6 lg:w-auto lg:self-center ml-auto flex-shrink-0' onClick={handleShowOnShop}>
          {t('cards.button')}
        </Button>
      </div>
    </Card>
  );
};
