import { HeaderUI } from '../components/header';
import { layoutSelector, layoutSlice } from '../redux';
import logo from '@root/assets/logo.png';
import { useIsDesktop } from '@root/infra/device-type';
import { UserDropdown, HamburgerMenu, SettingsDropdown } from '@root/infra/layout';
import { HelpCenter } from '@root/modules/auth/components';
import { useRedirection } from '@root/modules/auth/hooks/use-redirection';
import { authSelector } from '@root/modules/auth/redux';
import { REDIRECTION_TYPES } from '@root/modules/auth/types';
import { selectRedirectInstanceTypeByUserSubscription } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { BodyText, Image } from '@root/shared/ui';
import { MenuIcon } from '@root/shared/ui/icons/menu-icon';
import { QuestionIcon } from '@root/shared/ui/icons/question-icon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const { Brand, HeadLink, Inner, MenuToggle, Wrapper } = HeaderUI;

export const RegularHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { redirect } = useRedirection();
  const isDesktop = useIsDesktop();

  const { hamburgerMenuIsOpen } = useSelector(layoutSelector);
  const { authed } = useSelector(authSelector);
  const { isAnnual } = useSelector(selectRedirectInstanceTypeByUserSubscription);

  const openMenu = useCallback(() => {
    if (hamburgerMenuIsOpen) {
      dispatch(layoutSlice.actions.closed());
    } else {
      dispatch(layoutSlice.actions.opened());
    }
  }, [dispatch, hamburgerMenuIsOpen]);

  const redirectToShop = useCallback(() => redirect(REDIRECTION_TYPES.SHOP, { isAnnual }), [redirect, isAnnual]);

  return (
    <Wrapper>
      <div className='flex items-center px-8 gap-8'>
        <div className='hidden lg:block'>
          <Link to='/'>
            <Brand>
              <Image src={logo} layout='responsive' alt='Shop' />
              <span className='ml-4 text-xs leading-tight'>
                Customer <br /> Portal
              </span>
            </Brand>
          </Link>
        </div>

        <Inner className='flex flex-1 justify-between align-center'>
          <div className='flex items-center lg:hidden'>
            <MenuToggle className='block lg:hidden' onClick={openMenu}>
              <MenuIcon />
            </MenuToggle>
            <Link to='/'>
              <Brand>
                <Image src={logo} layout='responsive' alt='Shop' />
                <span className='ml-4 text-xs leading-tight'>
                  Customer <br /> Portal
                </span>
              </Brand>
            </Link>
          </div>

          {!authed && (
            <div className='hidden lg:flex items-center navbar-left'>
              <BodyText className='pl-8 border-l py-4 border-gray-600'>We create products that empower people to be better</BodyText>
            </div>
          )}

          <div className='navbar-center hidden lg:flex items-center gap-4'>
            {/*<JoinDiscordButton />*/}
            <HelpCenter>
              <span className='flex items-center gap-2 hover:text-primary-500 cursor-pointer'>
                <QuestionIcon />
                <span className='text-sm'>{t('common:Get Help')}</span>
              </span>
            </HelpCenter>
          </div>

          <div className='flex items-center navbar-right'>
            <HeadLink className='text-sm redirect_to_shop_button hidden lg:block' onClick={redirectToShop}>
              {t('Shop')}
            </HeadLink>

            <div className='ml-5 lg:ml-12 lg:mr-14 mr-5 hidden lg:block'>
              <SettingsDropdown />
            </div>

            <UserDropdown />
          </div>
        </Inner>
      </div>

      {!isDesktop && <HamburgerMenu />}
    </Wrapper>
  );
};
