import { useRedirection } from '@root/modules/auth/hooks/use-redirection';
import { REDIRECTION_TYPES } from '@root/modules/auth/types';
import {
  purchasedProductsSelector,
  purchasedBundleSelector,
  selectRedirectInstanceTypeByUserSubscription,
} from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

export const useBundleInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { redirect } = useRedirection();

  const { isLoading: isPurchasesLoading, loaded: isPurchasesLoaded } = useSelector(purchasedProductsSelector);
  const digitalBundles = useSelector(purchasedBundleSelector);
  const { isAnnual } = useSelector(selectRedirectInstanceTypeByUserSubscription);

  const isLoading = isPurchasesLoading || !isPurchasesLoaded;

  const bundleItem = digitalBundles[0];
  const recentlyExpiredItem = digitalBundles
    .filter((sub) => sub.isExpired && sub.id !== bundleItem.id)
    .sort((a, b) => new Date(b.expireAt).getTime() - new Date(a.expireAt).getTime())[0];

  const toggleDropdown = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const renewSubscription = useCallback(
    (quantity: number) => {
      redirect(REDIRECTION_TYPES.RENEW, { planId: bundleItem.id, isAnnual, quantity });
    },
    [redirect, bundleItem, isAnnual],
  );

  const handleRedirectToShop = useCallback(() => {
    redirect(REDIRECTION_TYPES.SHOP, { isAnnual });
  }, [redirect, isAnnual]);

  const state = { isOpen, isLoading, bundleItem, recentlyExpiredItem };
  const handlers = { toggleDropdown, renewSubscription, handleRedirectToShop, handleOutsideClick };

  return [state, handlers] as [typeof state, typeof handlers];
};
