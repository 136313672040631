import { Id } from '@root/shared/constants';

export type Redirection = 'CP';

export enum REDIRECTION_TYPES {
  SHOP = 'shop',
  RENEW = 'renew',
  RETRY = 'retry',
  BUY = 'buy',
  SWITCH_TO_PLAN = 'switch-to-plan',
  VIEW_ITEM_ON_SHOP = 'view-item-on-shop',
  REQUEST_ORDER = 'requestOrder',
  MHA_LOGIN_SS0 = 'mha-login-sso',
  TOPUP = 'topup',
  REGISTRATION = 'registration',
  UPGRADE = 'upgrade',
}

export interface IRedirectionParams {
  topUpAmount: number;
  urlParam: string;
  planId: Id;
  retryOrderId: Id;
  requestOrderId: Id;
  isAnnual: boolean;
  isAddon: boolean;
  quantity?: number;
}
